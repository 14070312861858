import styles from './DiscountMembershipCampaignBanner.module.scss';
import { useMemo } from 'react';
import { track } from '@amplitude/analytics-browser';
import classNames from 'classnames';
import { YEAR_END_2024_EVENT } from 'common/utils';
import useGlobalStore from 'store/global';
import { getUserYearStartEvent } from 'common/utils/user';
import useStore from 'store/timeline';
import {
  MembershipPackageName,
  MembershipPackageRecurring,
} from 'common/interfaces/api';
import { ScreenName } from 'common/utils/pp_tracking';

const randomBgUrl = () => {
  const random = Math.floor(Math.random() * 2);
  if (random === 1) return '/images/banners/year-start-banner-1.jpg';
  return '/images/banners/year-start-banner-2.jpg';
};

const DiscountMembershipCampaignBanner = ({
  fallbackElement,
  className,
}: {
  fallbackElement?: JSX.Element;
  className?: string;
}) => {
  const bgUrl = useMemo(() => {
    return randomBgUrl();
  }, []);
  const setShowMembershipPayDialog = useGlobalStore(
    (state) => state.setShowMembershipPayDialog
  );
  const currentUser = useStore((state) => state.currentUser);
  const discountMembershipEvent = getUserYearStartEvent(currentUser);
  if (!discountMembershipEvent || !bgUrl)
    return fallbackElement ? fallbackElement : <></>;
  return (
    <div
      className={classNames(styles.banner, className ?? '')}
      onClick={() => {
        track('Click Top Banner', { banner_type: YEAR_END_2024_EVENT });
        setShowMembershipPayDialog({
          open: true,
          initPackage: MembershipPackageName.Platinum,
          screen: ScreenName.HomeTimeline,
          initRecurring: MembershipPackageRecurring.Month,
        });
      }}
    >
      <img src={bgUrl} width="100%" />
    </div>
  );
};

export default DiscountMembershipCampaignBanner;
