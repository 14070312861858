import styles from './IgLPBanner.module.scss';
import { track } from '@amplitude/analytics-browser';
import classNames from 'classnames';
import useStore from 'store/timeline';

const IgLpBanner = ({ className }: { className?: string }) => {
  const currentUser = useStore((state) => state.currentUser);
  return (
    <div
      className={classNames(styles.banner, className ?? '')}
      onClick={() => {
        track('Click IG Timeline Banner', {
          user_id: currentUser?.id?.toString(),
        });
        window
          .open(`/rdt/ig-lp?user_id=${currentUser?.id?.toString()}`, '_blank')
          ?.focus();
      }}
    >
      <img src="/images/banners/ig-special-lp-banner.jpg" width="100%" />
    </div>
  );
};

export default IgLpBanner;
