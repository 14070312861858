import { MissionCode, MissionTaskStatus } from 'service/user';
import useStore from 'store/timeline';
import styles from './WebullBanner.module.scss';
import { useMemo } from 'react';
import { track } from '@amplitude/analytics-browser';
import classNames from 'classnames';

const randomBgUrl = (coinNumber: number) => {
  if (coinNumber === 10000) {
    return `/images/banners/webull-banner-10000-20250113.jpg`;
  } else {
    return '/images/banners/webull-banner-5000.jpg';
  }
};

const WebullBanner = ({
  fallbackElement,
  className,
}: {
  fallbackElement?: JSX.Element;
  className?: string;
}) => {
  const allMissions = useStore((state) => state.allMissions);
  const webullMission = useMemo(() => {
    return allMissions.find(
      (mission) => mission.code === MissionCode.WebullCreateAccount
    );
  }, [allMissions]);
  const showingBanner =
    webullMission &&
    [MissionTaskStatus.NotYet, MissionTaskStatus.InProgress].includes(
      webullMission?.current_task?.status
    );
  const bgUrl = useMemo(() => {
    return randomBgUrl(webullMission?.coin);
  }, [webullMission?.coin]);

  if (!showingBanner || !bgUrl)
    return fallbackElement ? fallbackElement : <></>;
  return (
    <div
      className={classNames(styles.banner, className ?? '')}
      onClick={() => {
        track('Click Top Banner', { banner_type: 'webull_banner' });
        window.open(webullMission?.link, '_blank')?.focus();
      }}
    >
      <img src={bgUrl} width="100%" />
    </div>
  );
};

export default WebullBanner;
