import styles from './IzanaviBanner.module.scss';
import { track } from '@amplitude/analytics-browser';
import classNames from 'classnames';
import useGlobalStore from 'store/global';

const IzanaviBanner = ({ className }: { className?: string }) => {
  const showIzanaviPRDialog = useGlobalStore(
    (state) => state.showIzanaviPRDialog
  );
  return (
    <div
      className={classNames(styles.banner, className ?? '')}
      onClick={() => {
        track('Click Top Banner', { banner_type: 'izanavi_banner' });
        showIzanaviPRDialog(true);
      }}
    >
      <img src="/images/izanavi/izanavi-yoko-banner-2025.jpg" width="100%" />
    </div>
  );
};

export default IzanaviBanner;
