import useStore, {
  INVESTMENT_TAB_NAME,
  TimelinePageTabs,
} from 'store/timeline';
import ImportantMessagesSection from 'components/common/ImportantMessagesSection';
import styles from './TimelinePage.module.scss';

import { createContext, useCallback, useMemo, useState } from 'react';
import RandomBanner from 'components/molecules/RandomBanner';
import { BannerCode } from 'common/utils/banner';
import { MissionCode, TopicType } from 'service/user';
import AnimeAlarmBanner from 'components/common/AnimeAlarmBanner';
import { useQuery } from 'react-query';
import {
  GetTimelinePostsResult,
  Post,
  PostParentKey,
} from 'common/interfaces/api';
import useGlobalStore from 'store/global';
import classNames from 'classnames';
import { LiveUserType, getInitTimelineData } from 'service/system';
import TimelineSection from 'components/TimelineSection';
import Icon, { LiveIcon } from 'components/common/Icon';
import RecommendTabSection from './RecommendTabSection';
import PostTopicsTabSection from './PostTopicsTabSection';
import { InvestmentLevel } from 'service/investment_level';
import { useMediaQuery } from 'common/utils';
import CreatePostBox from 'components/CreatePostBox';
import { track } from '@amplitude/analytics-browser';
import { SymbolTabsName } from 'common/utils/chart';

export type TimelinePageContextProps = {
  postTopics: TopicType[];
  isFetchedTimeline: boolean;
  trendingPosts: Post[];
  liveUsers: LiveUserType[];
  investmentLevels: InvestmentLevel[];
  symbolId: number;
};

export const TimelinePageContext =
  createContext<TimelinePageContextProps>(null);

export default function TimelinePage(): JSX.Element {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const currentUser = useStore((state) => state.currentUser);
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const [voteTabId, setVoteTabId] = useState(SymbolTabsName.VoteResult);
  const [currentTimelineTab, setCurrentTimelineTab] = useStore((state) => [
    state.currentTimelineTab,
    state.setCurrentTimelineTab,
  ]);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const randomBannerCodes = useMemo(() => {
    return [BannerCode.IgLp]
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  }, []);

  const topBanner = useMemo(() => {
    if (voteTabId === SymbolTabsName.ScoringModel) {
      return (
        <RandomBanner
          key={SymbolTabsName.ScoringModel}
          className={styles.banner}
          codes={[BannerCode.Izanavi]}
          isSupportRandom={false}
          fallbackCodes={[BannerCode.CoinInvite, BannerCode.Anime]}
          fallbackElement={
            <div className={styles.banner}>
              <AnimeAlarmBanner />
            </div>
          }
        />
      );
    }
    return (
      <RandomBanner
        className={styles.banner}
        codes={randomBannerCodes}
        isSupportRandom={false}
        fallbackCodes={[
          BannerCode.Izanavi,
          MissionCode.IGCreateAccount,
          MissionCode.InvastCreateAccount,
          MissionCode.WebullCreateAccount,
        ]}
        fallbackElement={
          <div className={styles.banner}>
            <AnimeAlarmBanner />
          </div>
        }
      />
    );
  }, [voteTabId, randomBannerCodes]);

  const getNextPageParamLivePosts = useCallback(
    (lastPage: GetTimelinePostsResult, pages: GetTimelinePostsResult[]) => {
      const posts = lastPage?.data?.posts;
      if (posts && posts.length > 0) {
        return pages.length + 1;
      }
    },
    []
  );

  const getNextPageParamPaidPosts = useCallback(
    (lastPage: GetTimelinePostsResult) => {
      const posts = lastPage?.data?.posts;
      if (posts && posts.length > 0 && lastPage?.data?.next_key) {
        return lastPage?.data?.next_key;
      }
    },
    []
  );
  const handleChooseTab = (tab: TimelinePageTabs) => {
    if (currentTimelineTab === tab) return;
    track('Click Tab At Home', { tab_name: tab });
    setCurrentTimelineTab(tab);
    setTimeout(scrollToTop, 0);
  };

  const { data: timelinePageInitialData, isFetched: isFetchedTimeline } =
    useQuery(
      'getTimelinePageInitialData',
      async () => {
        const response = await getInitTimelineData();
        const trendingPosts =
          response?.data?.sections?.[1]?.trending_posts || [];
        const domains = (response?.data?.sections?.[2]?.domains || []).reduce(
          (acc: TopicType[], domain, index) => {
            if (domain.id !== 0) {
              acc.push(domain);
            }
            if (index === 2) {
              acc.push({
                id: -1,
                name: INVESTMENT_TAB_NAME,
              });
            }
            return acc;
          },
          []
        );
        const symbolId = response?.data?.sections?.[3]?.symbol?.id || null;
        const liveUsers = response?.data?.sections?.[4]?.on_stream_users || [];
        const investmentLevels =
          response?.data?.sections?.[5]?.feed_investment_levels || [];
        return {
          posts: trendingPosts,
          domains,
          symbolId: symbolId,
          liveUsers,
          investmentLevels,
        };
      },
      {
        enabled: isAuthInit,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
        cacheTime: 15 * 60 * 1000,
      }
    );

  const symbolId = timelinePageInitialData?.symbolId || null;
  const tabContent = useMemo(() => {
    if (currentTimelineTab === TimelinePageTabs.Recommended) {
      return (
        <div className={styles.tabsContent}>
          <RecommendTabSection
            voteTabId={voteTabId}
            setVoteTabId={setVoteTabId}
          />
        </div>
      );
    }

    if (currentTimelineTab === TimelinePageTabs.LivePosts) {
      return (
        <div className={styles.tabsContent}>
          {!isMobile && (
            <div className={styles.createPost}>
              <CreatePostBox />
            </div>
          )}
          <div className={styles.postsList}>
            <TimelineSection
              apiUrl={`/feeds/lives`}
              queryKey={[PostParentKey.LiveTopics]}
              queryParamKey="page"
              getNextPageParam={getNextPageParamLivePosts}
              defaultPageParam={1}
            />
          </div>
        </div>
      );
    }

    if (currentTimelineTab === TimelinePageTabs.PrimeRegistration) {
      return (
        <div className={styles.tabsContent}>
          {!isMobile && (
            <div className={styles.createPost}>
              <CreatePostBox />
            </div>
          )}
          <div className={styles.postsList}>
            {currentUser?.id ? (
              <TimelineSection
                apiUrl={`/feeds/paid`}
                noResultMessage="現在プライム登録中のクリエイターはいません。<br/>お気に入りのクリエイターを見つけて、プライム登録してみましょう！"
                queryKey={[
                  PostParentKey.PaidPosts,
                  currentUser?.id?.toString(),
                ]}
                getNextPageParam={getNextPageParamPaidPosts}
                queryParamKey="next_key"
                defaultPageParam=""
              />
            ) : (
              <div className={styles.noPost}>
                <Icon name="empty" width={96} height={96} />
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      '現在プライム登録中のクリエイターはいません。<br/>お気に入りのクリエイターを見つけて、プライム登録してみましょう！',
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
      );
    }

    if (currentTimelineTab === TimelinePageTabs.PostTopics) {
      return (
        <div className={styles.tabsContent}>
          <PostTopicsTabSection />
        </div>
      );
    }
  }, [
    currentTimelineTab,
    currentUser?.id,
    getNextPageParamLivePosts,
    getNextPageParamPaidPosts,
    isMobile,
    voteTabId,
  ]);

  return (
    <TimelinePageContext.Provider
      value={{
        postTopics: timelinePageInitialData?.domains || [],
        isFetchedTimeline,
        trendingPosts: timelinePageInitialData?.posts || [],
        investmentLevels: timelinePageInitialData?.investmentLevels || [],
        liveUsers: timelinePageInitialData?.liveUsers || [],
        symbolId,
      }}
    >
      <div className={styles.timelinePage}>
        <ImportantMessagesSection />
        {currentUser && topBanner}
        <div className={styles.tabsGroup}>
          <div className={styles.tabsHeader}>
            <div
              className={classNames(styles.tab, {
                [styles.active]:
                  currentTimelineTab === TimelinePageTabs.Recommended,
              })}
              onClick={() => {
                handleChooseTab(TimelinePageTabs.Recommended);
              }}
            >
              <div className={styles.tabName}>おすすめ</div>
            </div>
            <div
              className={classNames(styles.tab, {
                [styles.active]:
                  currentTimelineTab === TimelinePageTabs.LivePosts,
              })}
              onClick={() => {
                handleChooseTab(TimelinePageTabs.LivePosts);
              }}
            >
              <div className={styles.tabName}>
                {timelinePageInitialData?.liveUsers?.length > 0 && (
                  <div className={styles.liveIcon}>
                    <LiveIcon width={20} height={20} stokeWidth={1.5} />
                  </div>
                )}
                LIVE
              </div>
            </div>
            <div
              className={classNames(styles.tab, {
                [styles.active]:
                  currentTimelineTab === TimelinePageTabs.PrimeRegistration,
              })}
              onClick={() => {
                handleChooseTab(TimelinePageTabs.PrimeRegistration);
              }}
            >
              <div className={styles.tabName}>プライム投稿</div>
            </div>
            <div
              className={classNames(styles.tab, {
                [styles.active]:
                  currentTimelineTab === TimelinePageTabs.PostTopics,
              })}
              onClick={() => {
                if (timelinePageInitialData?.domains?.length === 0) return;
                handleChooseTab(TimelinePageTabs.PostTopics);
              }}
            >
              <div className={styles.tabName}>トピック</div>
            </div>
          </div>
          {tabContent}
        </div>
      </div>
    </TimelinePageContext.Provider>
  );
}
